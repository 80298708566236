import {
  Button,
  Flex,
  Stack,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Text,
  Image,
  Box,
  Badge,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import { GoVerified, GoUnverified } from 'react-icons/go';
import { Field } from '../../../components/Form';
import { FormSection, FormSectionInner } from '../../../components/FormSection';
import { BiDownload } from 'react-icons/bi';
import { useSchemaMutation } from '../../../react-apollo-introspection';
import DeactivateUser from '../DeactivateUser';

const AthleteView = ({ data, deleteUser }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const [_deleteUser, { error: delteError }] = useSchemaMutation(deleteUser);

  const handleDelete = () => {
    console.log('deleteing');
    _deleteUser({
      variables: {
        _id: data._id,
      },
    }).then((res) => {
      window.location = '/athletes';
    });
  };
  return (
    <Flex direction='column' maxWidth='75%' w='100%'>
      <Flex mr='20px' mb='20px'>
        <FormSection
          title={
            <Flex ml='1em'>
              {data?.stripe?.digital_sub ? (
                <Badge
                  colorScheme='purple'
                  variant='solid'
                  // width='14ch'
                  textAlign='center'
                >
                  Digital Subscription
                </Badge>
              ) : (
                <Badge
                  colorScheme='green'
                  variant='solid'
                  // width='14ch'
                  textAlign='center'
                >
                  Free Subscription
                </Badge>
              )}
            </Flex>
          }
          p='0'
        >
          <FormSectionInner columns={1}>
            <Flex w='100%' align='center' justifyContent={'space-between'}>
              <Flex align='center'>
                {data?.profilePhoto?.cloudinaryLink && (
                  <Image
                    src={data.profilePhoto.cloudinaryLink}
                    height='50px'
                    mr='20px'
                  />
                )}
                <Flex direction={'column'}>
                  <Flex>
                    <Text
                      fontWeight='500'
                      fontSize='2em'
                      textTransform='capitalize'
                    >
                      {data?.firstNames}
                    </Text>
                    <Text
                      fontWeight='500'
                      fontSize='2em'
                      ml='5px'
                      textTransform='capitalize'
                    >
                      {data?.lastName}
                    </Text>
                  </Flex>
                  {data.sports.map((sport) => {
                    return (
                      <Box>
                        {' '}
                        <Text as='strong'>{sport.description}</Text>{' '}
                        <Flex direction={'column'}>
                          {sport.positions.map((position) => (
                            <Text>{position.label}</Text>
                          ))}{' '}
                        </Flex>
                      </Box>
                    );
                  })}
                </Flex>
              </Flex>
              <Flex direction={'column'} textAlign='center' align={'start'}>
                <Text as='strong'>GPA: {data?.gpa?.score}</Text>
                {data?.gpa?.uploads?.map((doc) => (
                  <Flex
                    justifyContent={'center'}
                    alignItems='center'
                    cursor={'pointer'}
                    onClick={() => window.open(doc.url, '_blank').focus()}
                  >
                    <BiDownload />
                    <Text ml='5px'>Transcript </Text>
                  </Flex>
                ))}
              </Flex>
            </Flex>
            <Flex justify='flex-end'>
              <DeactivateUser />
            </Flex>
          </FormSectionInner>
        </FormSection>
      </Flex>
      <Tabs
        orientation='vertical'
        w='100%'
        m='0'
        p='0'
        ml='-40px'
        align='start'
      >
        <TabList>
          <Tab justifyContent={'flexStart'}>Personal</Tab>
          <Tab justifyContent={'flexStart'}>Address</Tab>
          <Tab justifyContent={'flexStart'}>Education</Tab>
          <Tab justifyContent={'flexStart'}>Delete</Tab>
          {/* <Tab justifyContent={'flexStart'}>Sports</Tab> */}
        </TabList>

        <TabPanels>
          <TabPanel>
            <Stack spacing='20px' w='100%'>
              <FormSection title='Personal Details'>
                <FormSectionInner columns={2}>
                  <Field
                    component='text'
                    name='firstNames'
                    placeholder='First Name'
                    rules={{ required: true }}
                  />

                  <Field
                    component='text'
                    name='lastName'
                    placeholder='Last Name'
                    rules={{ required: true }}
                  />
                </FormSectionInner>
                <FormSectionInner columns={2}>
                  <Field
                    component='text'
                    name='email'
                    placeholder='Email Address'
                    rules={{ required: true }}
                  />
                  <Field component='text' name='ncaaId' placeholder='NCAA id' />
                </FormSectionInner>

                <FormSectionInner columns={2}>
                  <Field
                    component='date'
                    name='dateOfBirth'
                    placeholder='Date of Birth'
                    rules={{ required: true }}
                  />
                  <Field
                    component='text'
                    name='details.gender'
                    placeholder='Gender'
                    // labelKey='label'
                    // valueKey='value'
                    // options={[
                    //   { value: 'male', label: 'Male' },
                    //   { value: 'female', label: 'Female' },
                    //   { value: 'other', label: 'Other' },
                    // ]}
                    // selectValue='value'
                  />
                </FormSectionInner>

                <FormSectionInner columns={2}>
                  <Field component='switch' name='isMinor' label='is minor?' />
                </FormSectionInner>
                {data?.isMinor && (
                  <FormSectionInner columns={2}>
                    <Field
                      component='text'
                      name='parentEmail'
                      placeholder='Parent Email Address'
                      rules={{ required: true }}
                    />
                    <Flex align='center' mt='1em'>
                      {data?.parentAuthorisation?.hasAuthorised ? (
                        <GoVerified size={'1.5em'} color={'darkGreen'} />
                      ) : (
                        <GoUnverified size={'1.5em'} color={'red'} />
                      )}
                      <Text ml='1em'>
                        {data?.parentAuthorisation?.hasAuthorised
                          ? 'Verified'
                          : 'Not Verified'}
                      </Text>
                    </Flex>
                  </FormSectionInner>
                )}
              </FormSection>

              <FormSection title='Biometrics'>
                <FormSectionInner columns={1}>
                  <Text fontWeight='bold'>
                    Height: {data?.details?.biometrics?.height?.major?.value}{' '}
                    {data?.details?.biometrics?.height?.major?.unit}
                  </Text>

                  <Text fontWeight='bold'>
                    Weight: {data?.details?.biometrics?.weight?.value}{' '}
                    {data?.details?.biometrics?.weight?.unit}
                  </Text>
                </FormSectionInner>
              </FormSection>
            </Stack>
          </TabPanel>
          <TabPanel>
            <Flex mb='20px'>
              <FormSection title='Address'>
                <FormSectionInner columns={1}>
                  <Field
                    component='text'
                    name='address.lineOne'
                    placeholder='Line 1'
                    rules={{ required: true }}
                  />
                  <Field
                    component='text'
                    name='address.lineTwo'
                    placeholder='Line 2'
                  />
                  <Field
                    component='text'
                    name='address.city'
                    placeholder='City'
                    rules={{ required: true }}
                  />
                  <Field
                    component='text'
                    name='address.region'
                    placeholder='Region'
                    rules={{ required: true }}
                  />
                  <Field
                    component='text'
                    name='address.country'
                    placeholder='Country'
                    rules={{ required: true }}
                  />
                  <Field
                    component='text'
                    name='address.postcode'
                    placeholder='Postcode'
                    rules={{ required: true }}
                  />
                </FormSectionInner>
              </FormSection>
            </Flex>
          </TabPanel>

          <TabPanel>
            <Stack spacing='20px' w='100%'>
              <FormSection title='Education'>
                <FormSectionInner columns={1}>
                  {data.education.map((school, i) => {
                    return (
                      <Box key={`schol-${i}`}>
                        <Flex>
                          <Text as='strong'>School:</Text>
                          <Text ml={'5px'}>{school.school}</Text>
                        </Flex>
                        <Flex>
                          <Text as='strong'>Graduating:</Text>
                          <Text ml={'5px'}>
                            {dayjs(school.graduationYear).format('MMM/YYYY')}
                          </Text>
                        </Flex>
                      </Box>
                    );
                  })}
                  {/* <Field
                    component='repeater'
                    name='education'
                    placeholder='Education'
                    fieldArray={[
                      {
                        component: 'date',
                        name: 'graduationYear',
                        placeholder: 'Graduation Year',
                      },
                      {
                        component: 'text',
                        name: 'school',
                        placeholder: 'school',
                      },
                    ]}
                  /> */}
                </FormSectionInner>
              </FormSection>
              {/* <FormSection title='Resumes'>
                <FormSectionInner columns={1}>
                  <Field
                    component='repeater'
                    name='resumes'
                    placeholder='Resumes'
                    fieldArray={[
                      {
                        component: 'file',
                        name: 'resume',
                      },
                    ]}
                  />
                </FormSectionInner>
              </FormSection> */}
            </Stack>
          </TabPanel>
          <TabPanel>
            <FormSection title='Delete Athlete'>
              <FormSectionInner columns={1}>
                <Button colorScheme={'red'} onClick={onOpen}>
                  {' '}
                  Delete Account
                </Button>

                <AlertDialog
                  isOpen={isOpen}
                  leastDestructiveRef={cancelRef}
                  onClose={onClose}
                >
                  <AlertDialogOverlay>
                    <AlertDialogContent>
                      <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Delete Customer
                      </AlertDialogHeader>

                      <AlertDialogBody>
                        Are you sure? You can't undo this action afterwards.
                      </AlertDialogBody>

                      <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                          Cancel
                        </Button>
                        <Button
                          colorScheme='red'
                          onClick={() => handleDelete()}
                          ml={3}
                        >
                          Delete
                        </Button>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialogOverlay>
                </AlertDialog>
              </FormSectionInner>
            </FormSection>
          </TabPanel>
          {/* <TabPanel>
            <FormSection title="Athlete's Sports">
              <FormSectionInner columns={1}>
                {data.sports.map((sport) => {
                  return (
                    <Box>
                      {' '}
                      <Text as='strong'>{sport.description}</Text>{' '}
                      <Flex direction={'column'}>
                        {sport.positions.map((position) => (
                          <Text>{position.label}</Text>
                        ))}{' '}
                      </Flex>
                    </Box>
                  );
                })}
              </FormSectionInner>
            </FormSection>
          </TabPanel> */}
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default AthleteView;
