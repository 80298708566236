import React, { useEffect } from 'react';
import { Checkbox, Flex, FormLabel } from '@chakra-ui/react';

const CheckboxField = (props) => {
    const {
        onChange,
        onBlur,
        value,
        defaultValue,
        fieldLabel,
        error,
        ...rest
    } = props;

    useEffect(() => {
        if (value === '') {
            onChange(false);
        }
    }, [value, onChange]);

    return (
        <Flex
            align='center'
            w='100%'
            border={error ? '2px' : 0}
            rounded='md'
            borderColor='red.500'
            p={error ? '5px' : 0}
        >
            <Checkbox
                onChange={(e) => onChange(e.target.checked)}
                onBlur={() => {
                    onBlur && onBlur();
                }}
                value={value === true}
                isChecked={value === true}
                required={false}
                isRequired={false}
                defaultValue={defaultValue || false}
                {...rest}
            >
                {fieldLabel}
            </Checkbox>
        </Flex>
    );
};

export default CheckboxField;
