import React from 'react';
import { useRoutes } from 'react-router-dom';

import AdminLayout from '../containers/AdminLayout';
import { Login } from '../containers/Login';

import AdminUsers from '../containers/AdminUsers/setup';
import Athlete from '../containers/Athletes/setup';
import VideoGroups from '../containers/VideosGroups/setup';
import TrainingVideos from '../containers/TrainingVideos/setup';
import Checklist from '../containers/Checklist/setup';
import Pages from '../containers/Pages/setup';

const routes = [
  {
    path: '/',
    element: React.createElement(AdminLayout),
    children: [
      ...AdminUsers,
      ...Pages,
      ...Athlete,
      ...VideoGroups,
      ...TrainingVideos,
      ...Checklist,
    ],
  },
  {
    path: '/login',
    element: React.createElement(Login),
  },
];

const XRoutes = () => {
  const routeSetup = useRoutes(routes);

  return routeSetup;
};

export default XRoutes;
