const colours = {
    // blue: {
    //     50: '#E8F1FC',
    //     100: '#BFD7F8',
    //     200: '#96BDF3',
    //     300: '#6CA3EE',
    //     400: '#4389EA',
    //     500: '#1A6FE5',
    //     600: '#1559B7',
    //     700: '#104389',
    //     800: '#0A2D5C',
    //     900: '#05162E',
    // },
    purple: {
        50: '#EDECF8',
        100: '#CCCAEC',
        200: '#AAA8E0',
        300: '#8987D4',
        400: '#6865C8',
        500: '#4743BC',
        600: '#393696',
        700: '#2B2871',
        800: '#1C1B4B',
        900: '#0E0D26',
    },
    green: {
        50: '#ECF9F2',
        100: '#C9EED9',
        200: '#A6E3C1',
        300: '#83D8A9',
        400: '#60CD90',
        500: '#3DC278',
        600: '#309C60',
        700: '#247548',
        800: '#184E30',
        900: '#0C2718',
    },
    global: {
        bg: '#f0f2f5',
        elementBgLight: '#ffffff',
        text: 'rgba(0,0,0,0.9)',
        borderColour: 'rgba(0,0,0,0.1)',
    },
    inputs: {
        borderColour: '#CBD5E0',
        placeholderColour: '#A0AEC0',
    },
    grayBg: '#edf2f7',
    richText: {
        editorBg: '#ffffff',
    },
    success: '#27ad60',
};

export default colours;
