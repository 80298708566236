import React from 'react';
import { useFormState } from 'react-hook-form';
import { TiWarningOutline } from 'react-icons/ti';
import {
    Flex,
    Text,
    Tag,
    Icon,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    IconButton,
    Box,
    Button,
} from '@chakra-ui/react';
import { MdCheckCircleOutline, MdOutlineErrorOutline } from 'react-icons/md';

const InfoBar = () => {
    const { errors, isValid } = useFormState();

    return (
        <Menu>
            {!isValid ? (
                <MenuButton
                    as={IconButton}
                    variant='link'
                    colorScheme='red'
                    color='red.400'
                >
                    <MdOutlineErrorOutline fontSize='26px' />
                </MenuButton>
            ) : (
                <Box as={Button} variant='link' color='green.200'>
                    <MdCheckCircleOutline fontSize='26px' />
                </Box>
            )}
            <MenuList>
                {Object.values(errors) &&
                    Object.entries(errors).map(([key, value], index) => (
                        <MenuItem key={`error-${index}`}>
                            <Text fontSize='12px' key={`fieldError-${index}`}>
                                <Tag
                                    colorScheme='red'
                                    variant='subtle'
                                    mr='10px'
                                    size='sm'
                                >
                                    Required
                                </Tag>
                                {key}
                            </Text>
                            <Text>
                                {Array.isArray(value) &&
                                    value.map((key, i) =>
                                        Object.keys(key).map((k, idx) => (
                                            <Flex
                                                key={`sub-field-${i}-${idx}`}
                                                align='center'
                                                pl='20px'
                                            >
                                                <Icon mr='5px'>
                                                    <TiWarningOutline />{' '}
                                                </Icon>
                                                <Text fontSize='12px'>
                                                    Item {i + 1} - {k}
                                                </Text>
                                            </Flex>
                                        ))
                                    )}
                            </Text>
                        </MenuItem>
                    ))}
            </MenuList>
        </Menu>
    );
};

export default InfoBar;
