import { Alert, AlertIcon, Button, Stack } from '@chakra-ui/react';

import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { Field, Form } from '../../components/Form';
import { PasswordField } from '../../components/PasswordField';
import { generateSlugFromSite } from '../../helpers';
import { gql, useMutation } from '@apollo/client';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { authAtom } from '../../state/auth';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import { useSchemaMutation } from '../../react-apollo-introspection';

// const LOGIN = gql`
//   mutation Login($email: String!, $password: String!) {
//     adminLogin(email: $email, password: $password) {
//       token
//     }
//   }
// `;

export const LoginForm = () => {
  const [login, { data, loading, error }] = useSchemaMutation(
    'adminuser_Login'
  );
  const [auth, setAuth] = useRecoilState(authAtom);
  const location = useLocation();

  React.useEffect(() => {
    if (!auth && location.pathname === '/') {
      window.location.href = '/athletes';
    }
  }, [auth]);

  React.useEffect(() => {
    if (data && data.adminuser_Login.record) {
      setAuth(data.adminuser_Login.record);
      Cookies.set(
        `${generateSlugFromSite()}-token`,
        data.adminuser_Login.record.token,
        {
          expires: 7,
        }
      );
      window.location.href = '/athletes';
    }
  }, [data, setAuth]);

  return (
    <Form onSubmit={(v) => login({ variables: v })}>
      <Stack spacing='6'>
        <Field
          component='text'
          name='email'
          type='email'
          autoComplete='email'
          label='Email Address'
          rules={{ required: true }}
        />

        <Field
          name='password'
          component={PasswordField}
          rules={{ required: true }}
        />
        <SubmitButton loading={loading} />
      </Stack>
      {error && (
        <Alert status='error' mt='30px'>
          <AlertIcon />
          {error?.graphQLErrors[0]?.message}
        </Alert>
      )}
    </Form>
  );
};

const SubmitButton = ({ loading }) => {
  const { submit } = useFormContext();

  return (
    <Button
      isLoading={loading}
      isDisabled={loading}
      type='submit'
      onClick={() => submit()}
      size='lg'
      fontSize='md'
    >
      Sign in
    </Button>
  );
};

function handleAuthenticationError(err, setError) {
  const { status, message } = parseAuthenticationError(err);
  const errorType = message || status;
  console.log('MESSAGE', message);
  switch (errorType) {
    case 'invalid username':
      setError('Invalid email address.');
      break;
    case 'invalid username/password':
    case 'invalid password':
    case '401':
      setError('Incorrect username or password.');
      break;
    case 'name already in use':
    case '409':
      setError('Email is already registered.');
      break;
    case 'password must be between 6 and 128 characters':
    case '400':
      setError('Password must be between 6 and 128 characters.');
      break;
    case 'expected auth function result to be of type string or primitive.D but found <nil>':
      setError('You do not have admin permissions.');
      break;
    default:
      break;
  }
}

function parseAuthenticationError(err) {
  const parts = err.message.split(':');
  const reason = parts[parts.length - 1].trimStart();
  if (!reason) return { status: '', message: '' };
  const reasonRegex = /(?<message>.+)\s\(status (?<status>[0-9][0-9][0-9])/;
  const match = reason.match(reasonRegex);
  const { status, message } = match?.groups ?? {};
  return { status, message };
}
