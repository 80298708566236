import React from 'react';
import { Box, Flex, SimpleGrid, Text } from '@chakra-ui/react';

const FormSection = ({ title, children }) => {
    return (
        <Flex
            variant='form'
            bg='white'
            boxShadow='rgba(23, 24, 24, 0.05) 0px 0px 5px 0px, rgba(0, 0, 0, 0.15) 0px 1px 2px 0px'
            rounded='lg'
            w='100%'
            align='flex-start'
        >
            <Flex direction='column' w='100%' pb='20px'>
                <Flex p='20px' pb='0px' mb='20px' w='100%'>
                    {title && (
                        <Flex
                            w='100%'
                            fontWeight='600'
                            mb='0px'
                            fontSize='16px'
                        >
                            {title}
                        </Flex>
                    )}
                </Flex>
                {children}
            </Flex>
        </Flex>
    );
};

const FormSectionInner = ({
    title,
    description,
    p,
    children,
    borderTop,
    borderBottom,
    columns,
    ...rest
}) => {
    return (
        <Box
            px={p ? p : '20px'}
            {...rest}
            borderTop={borderTop ? '1px' : 0}
            borderBottom={borderBottom ? '1px' : 0}
            borderColor='gray.300'
        >
            {title && (
                <Text
                    textTransform='uppercase'
                    fontWeight='600'
                    fontSize='0.9rem'
                    pb={!description ? '10px' : 0}
                >
                    {title}
                </Text>
            )}
            {description && (
                <Text color='blackAlpha.600' fontSize='1rem' my='10px'>
                    {description}
                </Text>
            )}
            <SimpleGrid columns={columns} gap='5px 20px'>
                {children}
            </SimpleGrid>
        </Box>
    );
};

export { FormSection, FormSectionInner };
