import { Box } from '@chakra-ui/react';
import * as React from 'react';

import { Card } from '../../components/FormCard';
import LayoutWithLogo from '../../components/LayoutWithLogo';

import { LoginForm } from './LoginForm';

export const Login = () => (
    <LayoutWithLogo subtitle='Sign in to your dashboard'>
        <Box maxWidth='lg' mx='auto' w='100%'>
            <Card w='100%'>
                <LoginForm />
            </Card>
        </Box>
    </LayoutWithLogo>
);
