import React, { useContext, useEffect, useState, useRef } from 'react';
import FroalaEditor from 'froala-editor';
import styled from '@emotion/styled';
import { useTheme } from '@chakra-ui/react';

import { useRecoilState } from 'recoil';
import { mediaAtom as _mediaAtom } from '../../state/media';
import { CloudinaryUploadContext } from '../../providers/CloudinaryProvider';

/* eslint-disable */

const StyledContainer = styled.div`
    color: ${(props) => props.theme.colors.global.text};
    z-index: 0;
    position: relative;
    .fr-toolbar {
        background-color: rgba(0, 0, 0, 0.03);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        color: ${(props) => props.theme.colors.global.text};
        padding: 0px 0px;
        border: ${(props) => `1px solid ${props.theme.colors.gray['300']}`};
        z-index: 5000 !important;
        .fr-newline {
            background-color: transparent;
        }
        .fr-btn-grp {
            margin-left: 0px;
        }
        .fr-command.fr-btn {
            margin: 0;
            color: ${(props) => props.theme.colors.global.text};
            &:hover {
                background-color: ${(props) =>
                    props.theme.colors.global.elementBg};
            }
            svg {
                height: 20px;
            }
            &.fr-active svg path {
                fill: ${(props) => props.theme.colors.secondary};
            }
            &:not(.fr-active) svg path {
                fill: ${(props) => props.theme.colors.global.text};
            }

            &.fr-btn-active-popup {
                background: ${(props) => props.theme.colors.secondary};
                svg path {
                    fill: white;
                }
            }
        }
    }
    .fr-second-toolbar #fr-logo {
        display: none;
    }
    .fr-second-toolbar .fr-counter {
        font-size: 12px;
        padding-top: 3px;
    }
    .fr-popup {
        background-color: ${(props) => props.theme.colors.global.elementBgAlt};
        color: ${(props) => props.theme.colors.gray['400']};
        z-index: 9999;
        .fr-buttons {
            background-color: ${(props) =>
                props.theme.colors.global.elementBgAlt};
        }
        .fr-input-line {
            padding: 8px 0px;

            label {
                top: 23px;
                background: transparent;
                color: ${(props) => props.theme.colors.gray['400']};
            }
            input {
                background: ${(props) => props.theme.colors.whiteAlpha[100]};
                border-color: ${(props) => props.theme.colors.gray['400']};
                color: ${(props) => props.theme.colors.gray['400']};
                border-width: 1px;
                padding-bottom: 0px;
                padding-top: 6px;

                &:focus {
                    border-color: ${(props) => props.theme.colors.primary};
                    border-width: 1px;
                }
            }
        }
        .fr-checkbox input:checked + span {
            background: ${(props) => props.theme.colors.primary};
            border-color: ${(props) => props.theme.colors.primary};
            svg path {
                fill: white;
            }
        }
    }
    .fr-box {
        .fr-wrapper {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            background: ${(props) => props.theme.colors.richText.editorBg};
            color: ${(props) => props.theme.colors.global.text};
            border: ${(props) => `1px solid ${props.theme.colors.gray['300']}`};

            cursor: text;
        }
        .fr-element {
            color: ${(props) => props.theme.colors.global.text};
            padding: 7px 10px;
            a: {
                text-decoration: underline;
                color: ${(props) => props.theme.colors.secondary};
            }
        }
        .fr-placeholder {
            color: ${(props) => props.theme.colors.gray[500]};
            opacity: 0.9;
        }
    }
`;

const RichTextEditor = ({
    onChange,
    value,
    placeholder,
    maxCharacters,
    name,
}) => {
    const theme = useTheme();

    const { show } = useContext(CloudinaryUploadContext);
    const [mediaAtom, setMediaAtom] = useRecoilState(_mediaAtom);
    const [editor, setEditor] = useState(null);
    const [ready, setReady] = useState(false);
    const [froalaEditor, setFroalaEditor] = useState(null);

    useEffect(() => {
        if (value) {
            setReady(true);
            setEditor(value);
        } else {
            setReady(true);
            setEditor(null);
        }
    }, [value]);

    useEffect(() => {
        FroalaEditor.DefineIcon('cloudinaryImage', {
            NAME: 'Insert Image',
            SVG_KEY: 'insertImage',
        });
        FroalaEditor.RegisterCommand('cloudinaryImage', {
            title: 'Upload Image',
            focus: false,
            undo: false,
            refreshAfterCallback: false,
            callback: function () {
                show(localStorage.getItem('activeMediaField'));
            },
        });
        FroalaEditor.RegisterCommand('insertCloudinaryImage', {
            title: 'Upload Image',
            focus: false,
            undo: false,
            refreshAfterCallback: false,
            callback: function () {
                const field = mediaAtom.find(
                    (f) => f.name === localStorage.getItem('activeMediaField')
                );

                if (
                    field &&
                    localStorage.getItem('activeMediaField') === name
                ) {
                    froalaEditor.image.insert(
                        field.files[0].url,
                        null,
                        null,
                        null
                    );
                }
            },
        });
    }, [show]);

    const fEditor = useRef();

    useEffect(() => {
        if (ready) {
            var _editor = new FroalaEditor(
                fEditor.current,
                {
                    toolbarButtons: [
                        [
                            'bold',
                            'italic',
                            'underline',
                            'align',
                            'fontSize',
                            'textColor',
                            'subscript',
                            'superscript',
                        ],
                        [
                            'insertLink',
                            process.env.REACT_APP_UPLOAD_METHOD !== 'cloudinary'
                                ? 'insertImage'
                                : 'cloudinaryImage',

                            'insertVideo',

                            'insertTable',
                            'emoticons',
                            'specialCharacters',
                        ],
                        ['formatOL', 'formatUL'],
                    ],
                    key: 'te1C2sA5B5E6E5B4I4jC1QUd1Xd1OZJ1ABVJRDRNGGUE1ITrE1D4A3A10B1C6B5B1E4H3==',
                    maxCharacters: maxCharacters ? maxCharacters : 999999,
                    colorsText: [
                        '#1b4895',
                        '#e7599a',
                        '#06a5a6',
                        '#D83762',
                        '#76B6D8',
                        'REMOVE',
                        '#1C7A90',
                        '#249CB8',
                        '#4ABED9',
                        '#FBD75B',
                        '#FBE571',
                        '#FFFFFF',
                    ],
                    charCounterMax: maxCharacters ? maxCharacters : -1,
                    placeholderText: placeholder,
                    videoUpload: false,
                    toolbarInline: false,
                    toolbarSticky: true,
                    listAdvancedTypes: true,
                    pastePlain: true,
                    events: {
                        blur: function () {
                            onChange(this.html.get());
                        },

                        // 'image.beforeUpload': function (images) {
                        //     // Before image is uploaded
                        //     const data = new FormData();
                        //     data.append('file', images[0]);

                        //     Http.post('/file-upload', data, {
                        //         headers: {
                        //             accept: 'application/json',
                        //             'Accept-Language': 'en-US,en;q=0.8',
                        //             'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                        //         },
                        //     })
                        //         .then((res) => {
                        //             this.image.insert(
                        //                 `${process.env.REACT_APP_API}/${res.data.url}`,
                        //                 null,
                        //                 null,
                        //                 this.image.get()
                        //             );
                        //         })
                        //         .catch((err) => {
                        //             console.log(err);
                        //         });
                        //     return false;
                        // },
                    },
                },
                function () {
                    // Call the method inside the initialized event.
                    _editor.html.set(editor);
                }
            );

            setFroalaEditor(_editor);
        }
        return () => {
            if (editor) {
                _editor.destroy();
                setFroalaEditor(null);
            }
        };
    }, [ready]);

    useEffect(() => {
        const field = mediaAtom.find(
            (f) => f.name === localStorage.getItem('activeMediaField')
        );

        if (field && field.files.length !== 0) {
            FroalaEditor.COMMANDS.insertCloudinaryImage.callback.call();
        }
    }, [mediaAtom]);

    return (
        <StyledContainer
            theme={theme}
            onClick={() => localStorage.setItem('activeMediaField', name)}
        >
            <textarea ref={fEditor} onChange={(e) => setEditor(e.target.value)}>
                {editor}
            </textarea>
        </StyledContainer>
    );
};

export default RichTextEditor;
