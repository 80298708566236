import React, { useState } from 'react';
import { Badge, Flex, Input, Text as ChakraText } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';

const Text = (props) => {
  const {
    onFocus,
    onChange,
    onBlur,
    value,
    children,
    type,
    isRepeater = false,
    characterCount = false,
    maxLength,
    name,
    ...rest
  } = props;

  const data = useWatch({ name: name });

  if (characterCount && maxLength) {
    return (
      <Flex direction='column'>
        <Input
          onChange={(e) => onChange(e.target.value)}
          onBlur={() => {
            onBlur && onBlur();
          }}
          onFocus={() => {
            onFocus && onFocus();
          }}
          value={value}
          required={false}
          isRequired={false}
          minWidth={type === 'number' ? '100px' : '200px'}
          type={type}
          size='md'
          maxLength={maxLength}
          {...rest}
        />
        <Badge colorScheme='green' alignSelf='flex-end'>
          <ChakraText
            fontWeight='bold'
            fontSize='0.9em'
            letterSpacing={'1px'}
          >{`${
            data?.length ? data.length : 0
          }/${maxLength} characters`}</ChakraText>
        </Badge>
      </Flex>
    );
  } else
    return (
      <Input
        onChange={(e) => onChange(e.target.value)}
        onBlur={() => {
          onBlur && onBlur();
        }}
        onFocus={() => {
          onFocus && onFocus();
        }}
        value={value}
        required={false}
        isRequired={false}
        minWidth={type === 'number' ? '100px' : '200px'}
        type={type}
        size='md'
        {...rest}
      />
    );
};

export default Text;
