import { Spinner } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { Field } from '../../../components/Form';
import { useSchemaQuery } from '../../../react-apollo-introspection';

const GroupCodeSelect = () => {
  const FETCH = 'videogroups_List';

  const [getGroupCodes, { data, loading, error }] = useSchemaQuery(FETCH);

  const value = useWatch({ name: 'groupCode' });

  useEffect(() => {
    getGroupCodes();
  }, []);

  useEffect(() => {}, [value]);

  if (loading) <Spinner />;
  return (
    <Field
      name='groupCode'
      component='select'
      labelKey='label'
      valueKey='value'
      selectValue='value'
      options={data?.[FETCH]?.items?.map((op, i) => {
        return { value: op.code, label: `${op.code} - ${op.title}` };
      })}
    ></Field>
  );
};

export default GroupCodeSelect;
