import { useMutation } from '@apollo/client';
import { Box, ButtonGroup, Flex, Stack } from '@chakra-ui/react';
import React, { useState, useEffect, useRef, useCallback } from 'react';

import LoadingBar from 'react-top-loading-bar';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useSchemaMutation } from '../../react-apollo-introspection';
import { authAtom } from '../../state/auth';
import TableComponent from '../Table';
import Loading from '../Table/Loading';
import { tableAtom, tableFilterAtom } from './atoms';
import OperatorSearchSelect from './OperatorSearchSelect';
import SearchInput from './SearchInput';
import TableActions from './TableActions';

import TableSort from './TableSort';

const ListView = ({
  setup,
  columns,
  handleRowSelection,
  gqlDelete,
  filters,
  defaultFilter,
  renderRowSubComponent,
  fetchData = {},
  fetchFilters,
  loading,
  data: _data = [],
  error,
}) => {
  const ref = useRef(null);
  const currentUser = useRecoilValue(authAtom);
  const [selected, setSelected] = useState([]);
  const [tableState, setTableState] = useRecoilState(tableAtom);
  const [dataFilters, setFilters] = useRecoilState(tableFilterAtom);

  const data = _data?.items;
  const meta = _data?.pageInfo;

  /// DATA FETCHING

  useEffect(() => {
    fetchData({
      variables: {
        filter: { ...dataFilters, ...defaultFilter },
        sort: tableState.params.sort,
        perPage: tableState.params.pagination,
        skip: tableState.params.skip,
        limit: tableState.params.pagination,
        page: tableState.params.page,
        ...fetchFilters,
      },
      fetchPolicy: 'network-only',
    });
  }, [
    currentUser,
    dataFilters,
    tableState.params.sort,
    tableState.params.pagination,
    tableState.params.limit,
    tableState.params.skip,
    tableState.params.page,
  ]);

  useEffect(() => {
    if (loading && !data) {
      ref.current.continuousStart();
    }
    if ((!loading && data) || error) {
      ref.current.complete();
    }
  }, [loading]);

  /// ROW SELECTION

  const handleRowSelect = useCallback((rows) => {
    const flat = rows ? rows.map((d) => d.original[setup.accessor]) : [];
    setSelected((old) => {
      if (old != flat) {
        setSelected(flat);
      }
    });
    setTableState((old) => ({
      ...old,
      selected: flat,
      selectedRows:
        rows && rows.length !== 0 ? rows.map((d) => d.original) : [],
    }));
    if (handleRowSelection) {
      handleRowSelection(rows);
    }
  }, []);

  /// DELETE

  const [deleteItems] = useSchemaMutation(gqlDelete);

  const onDelete = () => {
    deleteItems({
      variables: { _ids: tableState.selected },
      onCompleted: () => window.location.reload(),
    });
  };

  /// SEARCHING

  const changeSearch = (value) => {
    setFilters((old) => ({ ...old, search: value }));
  };

  const onChangePageSize = useCallback(
    (size) => {
      setTableState((old) => ({
        ...old,
        params: { ...old.params, pagination: parseInt(size) },
      }));
    },
    [setTableState]
  );

  const onChangePage = useCallback(
    (page) => {
      setTableState((old) => ({
        ...old,
        params: { ...old.params, page },
      }));
    },
    [setTableState]
  );

  return (
    <Flex w='100%' direction='column' minHeight='500px'>
      <Box pos='fixed' zIndex={9999} w='100%' left={0} top={0}>
        <LoadingBar color='#366DDD' ref={ref} />
      </Box>
      <Flex align='center' p='10px' zIndex={100} pos='relative'>
        <Loading loading={loading} />
        <Stack isInline spacing='10px'>
          {setup.canSearch && <SearchInput changeSearch={changeSearch} />}

          <ButtonGroup
            size='md'
            isAttached
            variant='outline'
            colorScheme='gray'
          >
            <OperatorSearchSelect
              setup={setup}
              filters={filters}
              defaultFilter={defaultFilter}
            />
            <TableSort columns={columns} setup={setup} />
            <TableActions
              columns={columns}
              setup={setup}
              onDelete={onDelete}
              selected={selected}
            />
          </ButtonGroup>
        </Stack>
      </Flex>

      <TableComponent
        data={data ? data : []}
        meta={meta}
        columns={columns}
        onRowSelect={
          setup.canSelect === undefined
            ? handleRowSelect
            : setup.canSelect
            ? handleRowSelect
            : null
        }
        onChangePageSize={onChangePageSize}
        onChangePage={onChangePage}
        // onChangePage={(_page) => {
        //   setTableState((old) => ({
        //     ...old,
        //     params: { ...old.params, page: _page },
        //   }));
        // }}
        // onChangePageSize={(_page) => {
        //   setTableState((old) => ({
        //     ...old,
        //     params: { ...old.params, page: _page },
        //   }));
        // }}
        setup={setup}
        accessor={setup.accessor}
        loading={loading}
        error={error}
        renderRowSubComponent={renderRowSubComponent}
      />
    </Flex>
  );
};

export default ListView;
