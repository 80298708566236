import React, { useEffect, useState } from 'react';
import {
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
} from '@chakra-ui/react';

const Number = (props) => {
    const { onChange, onBlur, value, children, type, ...rest } = props;
    const [val, setVal] = useState('');

    const handleChange = (v) => {
        onChange(parseFloat(v));
        setVal(v);
    };

    useEffect(() => {
        if (value) {
            setVal(parseFloat(value));
        }
    }, [value]);

    return (
        <NumberInput
            onChange={(e) => handleChange(e)}
            onBlur={() => {
                onBlur && onBlur();
            }}
            value={val}
            required={false}
            isRequired={false}
            minWidth={type === 'number' ? '100px' : '200px'}
            type={type}
            {...rest}
        >
            <NumberInputField />
            <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
            </NumberInputStepper>
        </NumberInput>
    );
};

export default Number;
