import { useState } from "react"






function useBuildServer () {
    const [data, setData] = useState()
    const [error, setError] = useState()
    const [called, setCalled] = useState()
    const [loading,setLoading] = useState()
    let url
    switch (true) {
        case (process.env.REACT_APP_BUILDSERVER_URL !== undefined):
            url = process.env.REACT_APP_BUILDSERVER_URL
            break
        case (process.env.NEXT_PUBLIC_BUILDSERVER_URL !== undefined):
            url = process.env.NEXT_PUBLIC_BUILDSERVER_URL
            break
        default:
            return console.log('env build urls are not available')
    }


    const push = (type, data) => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify(data)
    
    
        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          }
          
          fetch(`${url}/graphql/schema/${type}`, requestOptions)
            .then(result => { 
                console.log('success, pushed to build server') 
            })
            .catch(error => console.log('error pushing to build server', error));
    }

    const query = function () { 
        setCalled(true)
        setLoading(true)
        fetch(url)
            .then((res) => {
                switch (res?.status) {
                    case (200):
                        console.log('build server set', url);
                        setData({ url, push })
                        break
                    default:
                        setError('non status code 400')
                }
                setLoading(false)
                
            })
            .catch(() => {
                setData(undefined)
                setLoading(false)
                setError('failed to ping build server, warning: push functions disabled')
            })
    }
    
    return [query, { data, error, called, loading }]
}

export default useBuildServer