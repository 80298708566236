import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

/// Palette
import colors from './colours';

/// Components
import Container from './components/Container';
import Input from './components/Input';
import Select from './components/Select';
import Button from './components/Button';
import Text from './components/Text';
import Textarea from './components/Textarea';
import Checkbox from './components/Checkbox';
import Menu from './components/Menu';
import NumberInput from './components/NumberInput';
import Radio from './components/Radio';

// 2. Update the breakpoints as key-value pairs
const breakpoints = createBreakpoints({
    sm: '320px',
    md: '768px',
    lg: '960px',
    xl: '1500px',
});

const overrides = {
    styles: {
        global: ({ colorMode }) => ({
            'html, body': {
                fontSize: '14px',
                bg: colorMode === 'light' ? '#F6F6F7 !important' : 'gray.900',
                overflowX: 'hidden',
                scrollBehavior: 'smooth',
            },
            body: {},
        }),
    },
    colors,

    breakpoints,
    components: {
        Container,
        Input,
        Select,
        Button,
        Text,
        Textarea,
        Checkbox,
        Menu,
        NumberInput,
        Radio,
    },
    config: {
        cssVarPrefix: 'x',
    },
};
export default extendTheme(overrides);
