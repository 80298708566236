import React from 'react';
import { Helmet } from 'react-helmet';
import config from '../../config.json';

const Meta = ({ title, children }) => {
    return (
        <>
            <Helmet>
                <title>{`${title} - ${config.project} CMS`}</title>
            </Helmet>
            {children}
        </>
    );
};

export default Meta;
