import React from 'react';
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    createHttpLink,
} from '@apollo/client';

import Cookies from 'js-cookie';

const ApolloProviderComponent = ({ children }) => {
    const siteName = process.env.REACT_APP_SITENAME;
    const httpLink = createHttpLink({
        uri: `${process.env.REACT_APP_API}`,
        fetch: async (uri, options) => {
            const accessToken = Cookies.get(
                `${siteName.split(' ').join('_')}-token`
            );
            options.headers.Authorization = `${accessToken}`;
            return fetch(uri, options);
        },
    });

    const client = new ApolloClient({
        link: httpLink,
        cache: new InMemoryCache(),
        query: {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        },
    });

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloProviderComponent;
