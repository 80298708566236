import { useLazyQuery } from '@apollo/client';
import { Box, Flex, Grid, Stack } from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import EditView from '../../components/EditView';
import { Field } from '../../components/Form';
import Meta from '../../components/Meta';
import { CREATE, EDIT, FETCH, DELETE } from './graphql';

import { setup } from './setup';

import DeactivateUser from './DeactivateUser';
import { useSchemaQuery } from '../../react-apollo-introspection';
import { useState } from 'react';
import { AiFillEye } from 'react-icons/ai';
import AthleteView from './components/AthleteView';

const Index = ({ title }) => {
  const params = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [fetchData, { data, loading, error }] = useSchemaQuery(FETCH);

  const type = params.type.charAt(0).toUpperCase() + params.type.slice(1);

  return (
    <Meta title={` ${type} ${title}`}>
      <EditView
        fetchData={fetchData}
        loading={loading}
        error={error}
        data={data}
        setup={setup}
        gqlFetch={FETCH}
        gqlEdit={EDIT}
        gqlCreate={CREATE}
      >
        <AthleteView data={data?.[FETCH]} deleteUser={DELETE} />
      </EditView>
    </Meta>
  );
};

export default Index;
