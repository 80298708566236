import { useLazyQuery } from '@apollo/client';
import { Flex, Grid, Stack } from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import EditView from '../../components/EditView';
import { Field } from '../../components/Form';
import { FormSection, FormSectionInner } from '../../components/FormSection';
import Meta from '../../components/Meta';
import { useSchemaQuery } from '../../react-apollo-introspection';
import { authAtom } from '../../state/auth';
import { EDIT, FETCH, CREATE } from './graphql';

import { setup } from './setup';

const Index = ({ title }) => {
  const params = useParams();
  const [fetchData, { data, loading, error }] = useSchemaQuery(FETCH);
  const [auth, setAuth] = useRecoilState(authAtom);
  const type = params.type.charAt(0).toUpperCase() + params.type.slice(1);

  return (
    <Meta title={` ${type} ${title}`}>
      <EditView
        fetchData={fetchData}
        loading={loading}
        error={error}
        data={data}
        setup={setup}
        gqlFetch={FETCH}
        gqlEdit={EDIT}
        gqlCreate={CREATE}
        editFields={['title', 'code']}
      >
        <Grid
          templateColumns={{ base: '1fr' }}
          w='100%'
          maxWidth='1000px'
          gap='25px'
        >
          <Stack spacing='20px' w='100%'>
            <FormSection
              title={
                type === 'edit'
                  ? `${data?.[FETCH]?.title}`
                  : 'Create a video group'
              }
            >
              <Flex direction={'column'}>
                <FormSectionInner columns={2}>
                  <Field
                    component='text'
                    name='title'
                    placeholder='Title'
                    rules={{ required: true }}
                  />
                  <Field
                    component='number'
                    name='code'
                    placeholder='Video group code'
                    rules={{ required: true }}
                  />
                </FormSectionInner>
              </Flex>
            </FormSection>
          </Stack>
        </Grid>
      </EditView>
    </Meta>
  );
};

export default Index;
