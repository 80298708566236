import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Flex } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import omitDeep from 'omit-deep-lodash';

const Form = (props) => {
    const {
        children,
        onSubmit,
        data,
        validationSchema,
        ignoreValues = [],
        defaultValues,
    } = props;

    const methods = useForm({
        shouldUnregister: false,
        mode: 'onChange',
        defaultValues: data ? data : defaultValues ? defaultValues : {},
        reValidateMode: 'onBlur',
        shouldFocusError: false,
        resolver: validationSchema ? yupResolver(validationSchema) : null,
    });

    const submitForm = async () => {
        //get filled values
        const val = await methods.getValues();

        let toBeIgnored = [
            'createdAt',
            'updatedAt',
            '__typename',
            ...ignoreValues,
        ];

        await toBeIgnored.forEach((e) => delete val[e]);

        async function send() {
            //trigger validation on fields
            const valid = await methods.trigger();

            if (Object.keys(methods.formState.errors).length === 0 && valid) {
                onSubmit(omitDeep(val, '__typename'));
            }
        }

        send();
    };
    //(onSubmit ? onSubmit(data) : console.log(data))

    /* eslint-disable */
    React.useEffect(() => {
        if (defaultValues && !data) {
            methods.reset(defaultValues);
        }

        if (data && Object.values(data).length !== 0) {
            methods.reset(data);
        }
        return () => {
            methods.reset({});
        };
    }, [data]);

    return (
        <FormProvider {...methods} submit={submitForm} data={data}>
            <Flex
                as='form'
                onSubmit={(e) => {
                    e.preventDefault();
                    // submitForm();
                }}
                w='100%'
                justify='center'
                direction='column'
                noValidate
            >
                {children}
            </Flex>
        </FormProvider>
    );
};

export default Form;
