const Select = {
    parts: ['field'],
    defaultProps: {
        size: 'md',
        isFullWidth: true,
    },
    baseStyle: {},
    variants: {
        outline: () => ({
            field: {
                outlineOffset: '0px',
                boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 0px 0px',
                borderColor: 'gray.300',
                borderTopColor: 'gray.400',
            },
        }),
    },
};

export default Select;
