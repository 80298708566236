import React from 'react';
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    Input,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
    useDisclosure,
    MenuList,
    MenuItem,
    Menu,
    Stack,
    Box,
    useOutsideClick,
} from '@chakra-ui/react';

const InputWithSelect = (props) => {
    const {
        onFocus,
        onChange,
        onBlur,
        value,
        children,
        type,
        isRepeater = false,
        options,
        name,
        ...rest
    } = props;

    const initialFocusRef = React.useRef();

    const { isOpen, onOpen, onClose } = useDisclosure();

    useOutsideClick({
        ref: initialFocusRef,
        handler: () => onClose(),
    });

    return (
        <Popover
            initialFocusRef={initialFocusRef}
            isOpen={isOpen}
            placement='bottom-start'
        >
            <PopoverTrigger>
                <Input
                    ref={initialFocusRef}
                    onChange={(e) => onChange(e.target.value)}
                    onBlur={() => {
                        onClose();
                    }}
                    onFocus={() => {
                        onOpen();
                    }}
                    value={value}
                    required={false}
                    isRequired={false}
                    minWidth={type === 'number' ? '100px' : '200px'}
                    type={type}
                    size='md'
                    {...rest}
                />
            </PopoverTrigger>

            <PopoverContent>
                <PopoverBody p='3px'>
                    <Stack spacing='2px' p='0px'>
                        {options.map((option, i) => (
                            <Box
                                py='4px'
                                px='10px'
                                rounded='md'
                                _hover={{
                                    bg: 'blackAlpha.100',
                                    cursor: 'pointer',
                                }}
                                key={`menu-option-${name}-${i}`}
                                onClick={() => {
                                    onChange(option.value);
                                    onClose();
                                }}
                                bg={
                                    value === option.value
                                        ? 'blue.100'
                                        : 'white'
                                }
                            >
                                {option.label}
                            </Box>
                        ))}
                    </Stack>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};

export default InputWithSelect;
