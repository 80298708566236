import {
  Badge,
  Box,
  Button,
  Flex,
  Input,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import React, { useState, useEffect } from 'react';

import { FaCheck } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import HeaderWithActions from '../../components/HeaderWithActions';
import ListView from '../../components/ListView';
import Meta from '../../components/Meta';
import { useSchemaQuery } from '../../react-apollo-introspection';

import { DELETE, FETCH_ALL } from './graphql';
import { setup } from './setup';
const columns = [
  {
    Header: 'Name',
    accessor: '_id',
    disableSortBy: true,
    Cell: ({ row }) => (
      <Link to={`/${setup.model}/edit/${row.original[setup.accessor]}`}>
        <Button variant="link">
          {row?.original?.firstNames} {row?.original?.lastName}
        </Button>
      </Link>
    ),
  },

  {
    Header: 'Email',
    accessor: 'email',
    disableSortBy: false,
    Cell: ({ row, value }) => (
      <Flex justify="space-between">
        <Text>{value}</Text>
        {row.original.emailValidation.isValidated && (
          <FaCheck fontSize={'1.5em'} color="green" />
        )}
      </Flex>
    ),
  },
  {
    Header: 'Parent Email',
    accessor: 'parentEmail',
    disableSortBy: false,
    Cell: ({ row, value }) => (
      <Flex justify="space-between">
        <Text>{value}</Text>
        {row.original.parentAuthorisation.hasAuthorised && (
          <FaCheck fontSize={'1.5em'} color="green" />
        )}
      </Flex>
    ),
  },
  // {
  //   Header: 'Parent Authorisation',
  //   accessor: 'parentAuthorisation.hasAuthorised',
  //   disableSortBy: true,
  //   Cell: ({ row }) =>
  //     !row.original?.isMinor ? (
  //       <Badge colorScheme='grey' width='14ch' textAlign='center'>
  //         -
  //       </Badge>
  //     ) : row.original.parentAuthorisation.hasAuthorised === true ? (
  //       <Badge colorScheme='green' width='14ch' textAlign='center'>
  //         AUTHORISED
  //       </Badge>
  //     ) : (
  //       <Badge
  //         colorScheme='red'
  //         variant='solid'
  //         width='14ch'
  //         textAlign='center'
  //       >
  //         UNAUTHORISED
  //       </Badge>
  //     ),
  // },
  {
    Header: 'Verified',
    accessor: 'isVerified',
    disableSortBy: false,
    Cell: ({ value }) =>
      value === true ? (
        <Badge colorScheme="green" width="14ch" textAlign="center">
          VERIFIED
        </Badge>
      ) : (
        <Badge
          colorScheme="red"
          variant="solid"
          width="14ch"
          textAlign="center"
        >
          UNVERIFIED
        </Badge>
      ),
  },
  {
    Header: '',
    accessor: 'profile_link',

    Cell: ({ row }) => (
      <a
        href={`${process.env.REACT_APP_FRONTEND_URL}/profile/${row.original._id}`}
        passHref
      >
        <Button variant="link">View Profile</Button>
      </a>
    ),
  },
];

const filters = [
  {
    value: 'last_name',
    type: 'text',
    label: 'Last Name',
    isOperator: false,
  },
];

const List = ({ title }) => {
  const [fetchData, { loading, error, data }] = useSchemaQuery(FETCH_ALL);
  const [inputValue, setInputValue] = useState(null);
  const [debouncedValue, setDebouncedValue] = useState('');

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, 500); // Adjust the delay time as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(timerId);
    };
  }, [inputValue]);

  useEffect(() => {
    if (debouncedValue) {
      fetchData({
        variables: {
          custom: {
            name: debouncedValue,
          },
        },
      });
    }
  }, [debouncedValue]);
  return (
    <Meta title={setup.title}>
      <HeaderWithActions title={setup.title}>
        <Stack ml="auto" isInline spacing="15px">
          {setup.canAddNew && (
            <Link to={`/${setup.model}/create`}>
              <Button>Add {setup.singular}</Button>
            </Link>
          )}
        </Stack>
      </HeaderWithActions>
      <Box
        mt="25px"
        bg={useColorModeValue('white', 'gray.700')}
        shadow="base"
        rounded={{
          base: 'md',
          sm: 'xl',
        }}
        w="100%"
      >
        <Flex p="5px">
          {' '}
          <Input
            placeholder="Search by name"
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
          />
        </Flex>
        <ListView
          setup={setup}
          fetchData={fetchData}
          fetchFilters={{
            custom: {
              stripe: {
                disable: true,
              },
            },
          }}
          data={data?.[FETCH_ALL]}
          loading={loading}
          error={error}
          gqlDelete={DELETE}
          columns={columns}
          filters={filters}
        />
      </Box>
    </Meta>
  );
};

export default List;
