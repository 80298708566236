import { useLazyQuery } from '@apollo/client';
import { Flex, Grid, Stack } from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import EditView from '../../components/EditView';
import { Field } from '../../components/Form';
import { FormSection, FormSectionInner } from '../../components/FormSection';
import Meta from '../../components/Meta';
import { useSchemaQuery } from '../../react-apollo-introspection';
import { authAtom } from '../../state/auth';
import GroupCodeSelect from './components/GroupCodeSelect';
import { EDIT, FETCH, CREATE } from './graphql';

import { setup } from './setup';

const Index = ({ title }) => {
  const params = useParams();
  const [fetchData, { data, loading, error }] = useSchemaQuery(FETCH);
  const [auth, setAuth] = useRecoilState(authAtom);
  const type = params.type.charAt(0).toUpperCase() + params.type.slice(1);

  return (
    <Meta title={` ${type} ${title}`}>
      <EditView
        fetchData={fetchData}
        loading={loading}
        error={error}
        data={data}
        setup={setup}
        gqlFetch={FETCH}
        gqlEdit={EDIT}
        gqlCreate={CREATE}
      >
        <Grid
          templateColumns={{ base: '1fr' }}
          w='100%'
          maxWidth='1000px'
          gap='25px'
        >
          <Stack spacing='20px' w='100%'>
            <FormSection title={`${data?.[FETCH]?.title}`}>
              <FormSectionInner>
                <Field
                  component='text'
                  name='title'
                  placeholder='title'
                  rules={{ required: true }}
                />
                <Field
                  component={GroupCodeSelect}
                  name='groupCode'
                  placeholder='group code'
                  rules={{ required: true }}
                />
              </FormSectionInner>
              <FormSectionInner columns={2}>
                <Field
                  component='text'
                  name='linkId'
                  placeholder='link id'
                  rules={{ required: true }}
                />
                <Field
                  component='number'
                  name='displayRank'
                  placeholder='display rank'
                />
              </FormSectionInner>
              <FormSectionInner columns={2}>
                <Field
                  component='switch'
                  name='available'
                  placeholder='available'
                />
              </FormSectionInner>
              <FormSectionInner columns={1}>
                <Field
                  component='textarea'
                  name='summary'
                  placeholder='Add Summary'
                  help='optional'
                  maxLength={200}
                  characterCount={true}
                />
              </FormSectionInner>
              <FormSectionInner columns={1}>
                <Field
                  component='text'
                  name='linkURL'
                  placeholder='Add Url link'
                  help='optional'
                />
              </FormSectionInner>
            </FormSection>
          </Stack>
        </Grid>
      </EditView>
    </Meta>
  );
};

export default Index;
