import { Grid, Stack } from '@chakra-ui/react';
import React, { useState } from 'react';
import { FormSection, FormSectionInner } from '../../../components/FormSection';
import { Field } from '../../../components/Form';

const ChecklistForm = () => {
  return (
    <Grid
      templateColumns={{ base: '1fr' }}
      w='100%'
      maxWidth='1000px'
      gap='25px'
    >
      <Stack spacing='20px' w='100%'>
        <FormSection title={`Athlete Checklist`}>
          <FormSectionInner>
            <Field
              component='text'
              name='name'
              placeholder='Please Enter a Checklist Item'
            />
            <Field
              component='text'
              name='linkURL'
              placeholder='Add Url link'
              help='optional'
            />
          </FormSectionInner>
        </FormSection>
      </Stack>
    </Grid>
  );
};

export default ChecklistForm;
