import { useQuery, gql } from '@apollo/client';
import { createContext, useEffect, useState } from 'react';
import usageSchemaInit from "../assets/usageSchema"
import React from 'react';
import useBuildServer from "../hooks/useBuildServer"

const SchemaContext = createContext({
    usageSchema: null,
    fullSchema: null,
    loading: null,
    updateFullSchema: () => {},
    updateUsageSchema: () => {}
})

const GET_SCHEMA = gql`
        {
          __schema {
              description
              types {
                name
                fields {
                    name
                    type {
                        name
                        kind
                        ofType {
                            name
                            kind
                            ofType {
                                name
                                kind
                                ofType {
                                    name
                                    kind
                                }
                            }
                        }
                    }
                }
              }
              queryType {
                  name
                  fields {
                      name
                      args {
                        name
                        type {
                            name
                            kind
                            ofType {
                                name
                                kind
                                ofType {
                                    name
                                    kind
                                }
                            }
                        }
                      }
                      type {
                          kind
                          name
                      }
                  }
              }
              mutationType {
                name
                fields {
                    name
                    args {
                        name
                        type {
                            name
                            kind
                            ofType {
                                name
                                kind
                            }
                        }
                      }
                    type {
                        kind
                        name
                    }
                }
            }
          }
        }
      `

function SchemaContextProvider(props) {
    const [usageSchema, setUsageSchema] = useState(usageSchemaInit)
    const [fullSchema, setFullSchema] = useState()
    const [buildServer, setBuildServer] = useState()
    const [loading, setLoading] = useState(true)
    //console.log('>>> loading', loading);
    const { loading: intro_loading, data: intro_data, error: intro_error } = useQuery(GET_SCHEMA)
    const [query, { data: bs_data, called: bs_called, error: bs_error }] = useBuildServer()

    useEffect(() => {
        if (intro_loading) {
            setLoading(true)
        } 
        if (intro_data) {
            setFullSchema(intro_data?.__schema)
            setUsageSchema({ mutations: [], queries: [] })
            setLoading(false)
        }
    },[intro_data])

    useEffect(() => {
        if (intro_data) {
            if (!bs_called) {
                setLoading(true)
                query()
            }
            if (bs_data) {
                setLoading(false)
                setBuildServer(bs_data)
            }
            if (bs_error) {
                console.log('build server error >>>', bs_error)
                setLoading(false)
            }
        }

    }, [intro_data, bs_called, query, bs_data])

    //if (loading) return props.loading ? props.loading(intro_loading) : <p>graphql schema context loading. Pass in props.loading with context provider to use custom component </p> 
    if (intro_error) return props.error ? props.error(intro_error) : <p>error loading graphql schema. Pass in props.error with context provider to use custom component</p>

    let value = {
        setUsageSchema,
        usageSchema,
        buildServer,
        fullSchema,
        loading
    }

    return (
        <SchemaContext.Provider value={value}>
                {props.children}
        </SchemaContext.Provider>
    )
}

export { SchemaContext }

export default SchemaContextProvider