import React from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import PopConfirm from '../../components/PopConfirm';
import { useSchemaMutation } from '../../react-apollo-introspection';

const DeactivateUser = () => {
  const { id } = useParams();
  const status = useWatch({ name: 'isVerified' });

  const [toggleActivate, { loading, data }] = useSchemaMutation(
    'athletes_UpdateById'
  );

  const toggleVerification = () => {
    toggleActivate({
      variables: {
        record: {
          isVerified: !status,
        },
        _id: id,
      },

      refetchQueries: ['athletes_FindById'],
    });
  };

  return (
    <Flex>
      <PopConfirm
        onConfirm={() => toggleVerification()}
        title={`Are you sure you want to ${
          status ? 'unverify' : 'verify'
        } this athlete?`}
      >
        <Button
          colorScheme={status ? 'red' : 'green'}
          isLoading={loading}
          isDisabled={loading}
          minWidth={'250px'}
        >
          {status ? 'Unverify Athlete' : 'Verify Athlete'}
        </Button>
      </PopConfirm>
    </Flex>
  );
};

export default DeactivateUser;
