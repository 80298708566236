import { useLazyQuery, useQuery } from '@apollo/client';
import { Flex, Grid, Stack } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import EditView from '../../components/EditView';
import { Field } from '../../components/Form';
import { FormSection, FormSectionInner } from '../../components/FormSection';
import Meta from '../../components/Meta';
import { useSchemaQuery } from '../../react-apollo-introspection';
import { CREATE, EDIT, FETCH, FETCH_ROLES } from './graphql';

import { setup } from './setup';

const Index = ({ title }) => {
  const params = useParams();
  const [fetchData, { data, loading, error }] = useSchemaQuery(FETCH);
  const [
    fetchRoles,
    { data: roles, loading: rolesLoading, error: rolesError },
  ] = useSchemaQuery(FETCH_ROLES);

  const type = params.type.charAt(0).toUpperCase() + params.type.slice(1);

  useEffect(() => {
    fetchRoles();
  }, []);

  return (
    <Meta title={` ${type} ${title}`}>
      <EditView
        fetchData={fetchData}
        loading={loading}
        error={error}
        data={data}
        setup={setup}
        gqlFetch={FETCH}
        gqlEdit={EDIT}
        gqlCreate={CREATE}
      >
        <Grid
          templateColumns={{ base: '1fr', lg: '1fr 300px' }}
          w='100%'
          maxWidth='1000px'
          gap='25px'
        >
          <Stack spacing='20px' w='100%'>
            <FormSection title='Your Details'>
              <FormSectionInner>
                <Field
                  component='text'
                  name='name'
                  placeholder='Name'
                  rules={{ required: true }}
                />

                <Field
                  component='text'
                  name='email'
                  placeholder='Email'
                  rules={{ required: true }}
                />
              </FormSectionInner>

              <FormSectionInner title='Passwords'>
                <Field
                  component='text'
                  name='password'
                  placeholder='Password'
                  rules={{ required: true }}
                />
              </FormSectionInner>
            </FormSection>

            <FormSection title='Role & Permissions'>
              {' '}
              <FormSectionInner>
                <Field
                  name='permission_role'
                  component='select'
                  options={roles?.[FETCH_ROLES]?.items}
                  labelKey='description'
                  valueKey='_id'
                  selectValue='value'
                />
              </FormSectionInner>
            </FormSection>
          </Stack>
        </Grid>
      </EditView>
    </Meta>
  );
};

export default Index;
