import React from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import EditView from '../../components/EditView';

import Meta from '../../components/Meta';
import { useSchemaQuery } from '../../react-apollo-introspection';
import { authAtom } from '../../state/auth';

import { EDIT, FETCH, CREATE } from './graphql';

import { setup } from './setup';
import ChecklistForm from './components/ChecklistForm';

const Index = ({ title }) => {
  const [fetchData, { data, loading, error }] = useSchemaQuery(FETCH);
  const [auth, setAuth] = useRecoilState(authAtom);

  return (
    <Meta title={`Checklist`}>
      <EditView
        fetchData={fetchData}
        loading={loading}
        error={error}
        data={data}
        setup={setup}
        gqlFetch={FETCH}
        gqlEdit={EDIT}
        gqlCreate={CREATE}
      >
        <ChecklistForm />
      </EditView>
    </Meta>
  );
};

export default Index;
