import {
  Heading,
  Text,
  Flex,
  Image,
  useColorModeValue,
} from '@chakra-ui/react';
import * as React from 'react';
import config from '../config.json';

const LayoutWithLogo = ({ subtitle, children }) => (
  <Flex
    bg={useColorModeValue('gray.50', 'inherit')}
    minH='100vh'
    w='100vw'
    align='center'
    justify='center'
    py='12'
    px={{
      base: '4',
      lg: '8',
    }}
  >
    <Flex mx='auto' w='100%' direction='column' align='center' justify='center'>
      <Image
        mx='auto'
        h='30px'
        mb={{
          base: '10',
          md: '10',
        }}
        src='/logo.png'
      />
      <Heading textAlign='center' size='xl' fontWeight='extrabold'>
        {config.project}
      </Heading>
      <Text mt='4' mb='8' align='center' maxW='md' fontWeight='medium'>
        <Text as='span' opacity={0.6}>
          {subtitle}
        </Text>
      </Text>
      {children}
    </Flex>
  </Flex>
);

export default LayoutWithLogo;
