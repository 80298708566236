import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Button,
    Flex,
    Icon,
    Stack,
} from '@chakra-ui/react';
import React, { useContext } from 'react';

import { NucleusContext } from '../../../providers/XProvider';
import { MdKeyboardArrowDown } from 'react-icons/md';

import { Link } from 'react-router-dom';

const Sidebar = () => {
    const { links } = useContext(NucleusContext);

    const hasPermission = (permissions) => {
        // if (currentUser) {
        //     if (currentUser?.permissions?.some((e) => e.code === 'all')) {
        //         return true;
        //     }
        //     if (auth.currentUser?.permissions) {
        //         return checkPermissions({
        //             has: auth.currentUser.permissions,
        //             required: permissions,
        //         });
        //     } else {
        //         return true;
        //     }
        // } else {
        //     return false;
        // }
        return true;
    };

    return (
        <Flex
            w={{ base: '180px', xl: '240px' }}
            minWidth={{ base: '180px', xl: '240px' }}
            h='calc(100vh - 3.9rem)'
            borderRight='1px'
            borderColor='gray.300'
            py='20px'
            pos='fixed'
            left={0}
            top='3.9rem'
            bg='white'
        >
            <Stack w='100%' spacing='1px' px='10px'>
                {links &&
                    links.map((group, groupIndex) =>
                        group.items && group.items.length === 0
                            ? hasPermission(
                                  group.permissions ? group.permissions : []
                              ) && (
                                  <Link
                                      to={group.link}
                                      key={`group--${groupIndex}`}
                                  >
                                      <Button
                                          isFullWidth
                                          justifyContent={'flex-start'}
                                          variant='ghost'
                                          color='gray.800'
                                          colorScheme='blackAlpha'
                                      >
                                          {group.group}
                                      </Button>
                                  </Link>
                              )
                            : hasPermission(
                                  group.permissions ? group.permissions : []
                              ) && (
                                  <Accordion
                                      key={`${group.group}-${groupIndex}`}
                                      collapsible='true'
                                      multiple
                                      borderColor='transparent'
                                      allowToggle
                                  >
                                      <AccordionItem>
                                          <AccordionButton p={0}>
                                              <Button
                                                  as='a'
                                                  mb={0}
                                                  align='center'
                                                  isFullWidth
                                                  variant='ghost'
                                                  color='gray.800'
                                                  colorScheme='blackAlpha'
                                              >
                                                  <Flex w='100%' align='center'>
                                                      {group.group}
                                                      <Icon
                                                          ml='auto'
                                                          fontSize='22px'
                                                      >
                                                          <MdKeyboardArrowDown />
                                                      </Icon>
                                                  </Flex>
                                              </Button>
                                          </AccordionButton>
                                          <AccordionPanel px={0} pl='10px'>
                                              {group.items &&
                                                  group.items.map(
                                                      (item, index) =>
                                                          hasPermission(
                                                              item.permissions
                                                                  ? item.permissions
                                                                  : []
                                                          ) && (
                                                              <Link
                                                                  to={item.href}
                                                                  key={`${item.title}-${index}-${groupIndex}`}
                                                              >
                                                                  <Button
                                                                      isChild
                                                                      isFullWidth
                                                                      variant='ghost'
                                                                      justifyContent='flex-start'
                                                                      color='gray.600'
                                                                      colorScheme='blackAlpha'
                                                                      fontWeight='normal'
                                                                  >
                                                                      {
                                                                          item.title
                                                                      }
                                                                  </Button>
                                                              </Link>
                                                          )
                                                  )}
                                          </AccordionPanel>
                                      </AccordionItem>
                                  </Accordion>
                              )
                    )}
            </Stack>
        </Flex>
    );
};

export default Sidebar;
