import React, { useEffect, useState } from 'react';
import { Flex, Textarea, Text, Badge } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';

const TextAreaComponent = (props) => {
  const {
    onChange,
    onBlur,
    value,
    resize = 'vertical',
    characterCount = false,
    maxLength,
    name,
    ...rest
  } = props;

  const data = useWatch({ name: name });

  if (characterCount && maxLength) {
    return (
      <Flex direction='column'>
        <Textarea
          onChange={(e) => onChange(e.target.value)}
          onBlur={() => {
            onBlur && onBlur();
          }}
          value={value}
          minWidth='200px'
          resize={resize}
          maxLength={maxLength}
          {...rest}
        />
        <Badge colorScheme='green' alignSelf='flex-end'>
          <Text fontWeight='bold' fontSize='0.9em' letterSpacing={'1px'}>{`${
            data?.length ? data.length : 0
          }/${maxLength} characters`}</Text>
        </Badge>
      </Flex>
    );
  } else
    return (
      <Textarea
        onChange={(e) => onChange(e.target.value)}
        onBlur={() => {
          onBlur && onBlur();
        }}
        value={value}
        minWidth='200px'
        resize={resize}
        {...rest}
      />
    );
};

export default TextAreaComponent;
