import { useContext, useEffect } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { SchemaContext } from '../component/SchemaContextProvider'
import processVariables from '../js/processVariables'
import processArguments from '../js/processArguments'
import getTypeFields from '../js/getTypeFields'



function useSchemaQuery (queryName, options) {
    const { usageSchema, fullSchema, buildServer, setUsageSchema, loading } = useContext(SchemaContext)

    if (fullSchema) {
        const query = fullSchema.queryType.fields.find(e => e.name === queryName)
        if (query) {
            let str = `query ${queryName}`
            if (query.args) str = processVariables(query.args, str)
            str += ` { \n ${queryName} `
            if (query.args) str = processArguments(query.args, str)
            str += ` ${getTypeFields(fullSchema, query.type.name)} }`
            const data = { queryName, str }
            if (!loading && !usageSchema.queries.find(q => q.queryName === queryName)) {
                usageSchema.queries.push(data)
                if (buildServer?.url) buildServer.push('query',data)
            }            
        }
    }
    
    useEffect(() => { if (fullSchema) setUsageSchema(usageSchema) }, [usageSchema,fullSchema,setUsageSchema])
    const detail = usageSchema?.queries?.find(e => e.queryName === queryName)
    
    const query_string = (!detail && loading) ? `query Query { loading_handler_query }` : detail?.str
    //console.log('query hook, loading >>>',loading, 'str >>>', query_string)
    try {
        const result = useLazyQuery(gql`${query_string}`,options)
        if (loading) {
            const [ query, res ] = result
            res.loading = loading
            delete res.data
        }
        return result
    } catch (error) {
        throw new Error(`failed to load appropriate info from intro data: ${queryName}`)
    }
    
}

export default useSchemaQuery