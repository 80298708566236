const Textarea = {
    defaultProps: {
        size: 'lg',
        isFullWidth: true,
    },
    baseStyle: {},
    variants: {},
};

export default Textarea;
