import { Box } from '@chakra-ui/react';
import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { NucleusContext } from './XProvider';
import { mediaAtom as _mediaAtom } from '../state/media';
import { useRecoilState } from 'recoil';
import { saveSiteToStorage } from '../helpers';
import sha256 from 'js-sha256';

export const CloudinaryUploadContext = createContext();

const CloudinaryProvider = ({ children }) => {
    const { config } = useContext(NucleusContext);

    const cloudinaryContainer = useRef();

    useEffect(() => {
        // Set the site from config
        saveSiteToStorage(config.siteSwitcher.sites);
    }, [config.siteSwitcher.sites]);

    const [mediaAtom, setMediaAtom] = useRecoilState(_mediaAtom);

    ///// CLOUDINARY

    const [ml, setML] = useState(null);

    const show = (name) => {
        localStorage.setItem('activeMediaField', name);
        ml.show();
    };

    useEffect(() => {
        if (process.env.REACT_APP_UPLOAD_METHOD === 'cloudinary') {
            // Initiate Cloudinary

            const timestamp = ((Date.now() / 1000) | 0).toString();
            const auth = `cloud_name=${process.env.REACT_APP_CLOUDINARY_CLOUDNAME}&timestamp=${timestamp}&username=${process.env.REACT_APP_CLOUDINARY_USERNAME}${process.env.REACT_APP_CLOUDINARY_SECRET}`;
            const signature = sha256(auth);

            const handleSelected = (files) => {
                const active = localStorage.getItem('activeMediaField');

                let fields = [...mediaAtom];

                const index = fields.findIndex((f) => f.name === active);

                if (index !== -1) {
                    fields[index] = {
                        ...fields[index],
                        files: files.assets,
                    };

                    setMediaAtom(fields);
                } else {
                    fields = [...fields, { name: active, files: files.assets }];
                    setMediaAtom(fields);
                }
            };

            if (!ml && typeof window.cloudinary !== 'undefined') {
                setTimeout(() => {
                    setML(
                        window.cloudinary.createMediaLibrary(
                            {
                                cloud_name:
                                    process.env.REACT_APP_CLOUDINARY_CLOUDNAME,
                                api_key: process.env.REACT_APP_CLOUDINARY_KEY,
                                username:
                                    process.env.REACT_APP_CLOUDINARY_USERNAME,
                                timestamp: timestamp,
                                signature: signature,
                            },
                            {
                                insertHandler: function (data) {
                                    handleSelected(data);
                                },
                            }
                        )
                    );
                }, 500);
            }
        }
    }, []);
    return (
        <CloudinaryUploadContext.Provider
            value={{ mediaLibrary: ml ? ml : null, show }}
        >
            {children}
            <Box ref={cloudinaryContainer} />
        </CloudinaryUploadContext.Provider>
    );
};

export default CloudinaryProvider;
