import React, { useEffect, useState, forwardRef } from 'react';

import DatePicker from 'react-datepicker';

import { InputGroup, Input, InputLeftAddon } from '@chakra-ui/react';

import { FiCalendar } from 'react-icons/fi';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const DatePickerComponent = (props) => {
    const {
        onChange,
        value,
        disabled,
        disableFuture,
        disablePast,
        inputProps,
        minDate,
        maxDate,
        clearable,
        onBlur,
        variant,
        size,
        placeholder,
        ...rest
    } = props;

    const [selectedDate, handleDateChange] = useState();

    const changeValue = (val) => {
        if (val) {
            handleDateChange(val);
            onChange(val);
        } else {
            handleDateChange(null);
            onChange(null);
        }
    };

    /* eslint-disable */
    useEffect(() => {
        if (selectedDate !== value) {
            handleDateChange(value);
        }
    }, [value]);

    const Field = forwardRef(({ value, onClick }, ref) => {
        return (
            <InputGroup ref={ref} size={size}>
                <InputLeftAddon
                    borderColor='gray.300'
                    children={<FiCalendar />}
                />

                <Input
                    placeholder={placeholder ? placeholder : 'Select Date'}
                    disabled={disabled}
                    onChange={null}
                    variant={variant}
                    size={size}
                    onFocus={() => onClick()}
                    value={value}
                    ref={ref}
                    bg='white'
                    autoComplete='off'
                    {...inputProps}
                />
            </InputGroup>
        );
    });

    return (
        <Styles>
            <DatePicker
                label='Date'
                selected={selectedDate ? selectedDate : null}
                closeOnScroll={true}
                onChange={(v) => changeValue(v)}
                animateYearScrolling={false}
                customInput={<Field />}
                showPopperArrow={false}
                disabledKeyboardNavigation
                {...rest}
                dateFormat='hh:mm'
            />
        </Styles>
    );
};

DatePickerComponent.defaultProps = {
    autoOk: true,
    format: 'hh:mm',
    inputProps: {
        variant: 'outline',
    },
    clearable: true,
    onChange: (date) => console.log(date),
    showMonthDropdown: true,
    showYearDropdown: true,
    dropdownMode: 'select',
};

DatePickerComponent.displayName = 'DatePicker';

DatePickerComponent.propTypes = {
    /**
     * Whether the datepicker should close on selection of a date
     */
    autoOk: PropTypes.bool,
    /**
     * The date format
     */
    format: PropTypes.string,
    /**
     * Function to fire when a date is selected.  Passes a moment object
     */
    onChange: PropTypes.func,
    /**
     * The value
     */
    value: PropTypes.string,
    /**
     * Disable future dates
     */
    disableFuture: PropTypes.bool,
    /**
     * Disable past dates
     */
    disablePast: PropTypes.bool,
    /**
     * The props for the input element (eg variant, width)
     */
    inputProps: PropTypes.object,
    /**
     * Max selectable date (ParsableDate)
     */
    maxDate: PropTypes.string,
    /**
     * Min selectable date (ParsableDate)
     */
    minDate: PropTypes.string,
    /**
     * The variant of the input field
     */
    variant: PropTypes.oneOf(['outline', 'filled', 'unstyled', 'flushed']),
    /**
     * The size of the input field
     */
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

export default DatePickerComponent;

const Styles = styled.div`
    .react-datepicker {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
        border-radius: 5px;

        .react-datepicker__time-list-item--selected {
            background-color: black !important;
        }

        .react-datepicker__header {
            border-bottom: 3px solid rgba(0, 0, 0, 0.05);
        }
    }
`;
