import { useContext, useEffect } from 'react'
import { SchemaContext } from '../component/SchemaContextProvider'
import processVariables from '../js/processVariables'
import processArguments from '../js/processArguments'
import getTypeFields from '../js/getTypeFields'
import { gql, useMutation } from '@apollo/client'






function useSchemaMutation (mutationName, options) {
    const { usageSchema, fullSchema, setUsageSchema, buildServer, loading } = useContext(SchemaContext)
    
    if (fullSchema) {
        const mutation = fullSchema.mutationType.fields.find(e => e.name === mutationName)
        if (!mutation) throw new Error('request query not found in schema')
        let str = `mutation ${mutationName} `
        if (mutation.args) str = processVariables(mutation.args, str)
        str += `\n { ${mutationName} `
        if (mutation.args) str = processArguments(mutation.args, str)
        str += ` ${getTypeFields(fullSchema, mutation.type.name)} }`
        
        const data = { mutationName, str }
        if (!loading && !usageSchema.mutations.find(m => m.mutationName === mutationName)) {
            usageSchema.mutations.push(data)
            if (buildServer?.url) { 
                buildServer.push('mutation',data)
            }
        }
    }

    useEffect(() => { if (fullSchema) setUsageSchema(usageSchema) }, [usageSchema,fullSchema,setUsageSchema])
    
    const detail = usageSchema?.mutations?.find(e => e.mutationName === mutationName)    
    let mutation_string = (!detail && loading) ? `mutation Mutation { loading_handler_mutation }` : detail?.str

    try {
        const result = useMutation(gql`${mutation_string}`,options)
        const [ mutation, res ] = result
        if (loading) {
            res.loading = loading
            delete res.data
        }
        useEffect(() => { if (!loading) res.reset() }, [loading])
        return result
    } catch (error) {
        console.log(error);
        throw new Error(`failed to load appropriate info from intro data: ${mutationName}`)
    }
    
}


export default useSchemaMutation