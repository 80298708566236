import { Box, Button, Flex, Stack } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { generateSlugFromSite } from '../../helpers';
import AuthProvider from '../../providers/AuthProvider';

import LogoHeading from './LogoHeading';

import Sidebar from './SideMenu';

const AdminLayout = () => {
    return (
        <AuthProvider>
            <Flex direction='column' w='100vw'>
                <Flex
                    w='100vw'
                    bg='gray.900'
                    px='15px'
                    h='3.9rem'
                    align='center'
                    boxShadow='0px 2px 2px -1px rgba(0,0,0,0.15)'
                    pos='fixed'
                    top='0px'
                    left='0px'
                    zIndex={5}
                    color='white'
                >
                    <LogoHeading />

                    {/* <Search /> */}

                    <Stack
                        isInline
                        ml='auto'
                        flex={1}
                        justify='flex-end'
                        color='white'
                        spacing='20px'
                    >
                        <Button
                            variant='ghost'
                            size='sm'
                            colorScheme='whiteAlpha'
                            onClick={() => {
                                Cookies.remove(
                                    `${generateSlugFromSite()}-token`
                                );
                                window.location.reload();
                            }}
                        >
                            Logout
                        </Button>
                    </Stack>
                </Flex>
                <Flex w='100%' justify='stretch'>
                    <Sidebar />
                    <Box
                        w='100%'
                        pl={{ base: '180px', xl: '240px' }}
                        pt='3.9rem'
                    >
                        <Box p={{ base: '15px', lg: '20px' }}>
                            <Outlet />
                        </Box>
                    </Box>
                </Flex>
            </Flex>
        </AuthProvider>
    );
};

export default AdminLayout;
