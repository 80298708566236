import { useLazyQuery } from '@apollo/client';
import {
  Flex,
  Grid,
  Stack,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import EditView from '../../components/EditView';
import { Field } from '../../components/Form';
import { FormSection, FormSectionInner } from '../../components/FormSection';
import Meta from '../../components/Meta';
import { useSchemaQuery } from '../../react-apollo-introspection';
import { EDIT, FETCH, CREATE } from './graphql';
import { useWatch } from 'react-hook-form';

import { setup } from './setup';

const Index = ({ title }) => {
  const params = useParams();
  const [fetchData, { data, loading, error }] = useSchemaQuery(FETCH);
  const type = params.type.charAt(0).toUpperCase() + params.type.slice(1);

  return (
    <Meta title={` ${type} ${title}`}>
      <EditView
        fetchData={fetchData}
        loading={loading}
        error={error}
        data={data}
        setup={setup}
        gqlFetch={FETCH}
        gqlEdit={EDIT}
        gqlCreate={CREATE}
      >
        <Flex direction='column' maxWidth='75%' w='100%'>
          <Tabs
            orientation='vertical'
            w='100%'
            m='0'
            p='0'
            ml='-40px'
            align='start'
          >
            <TabList>
              <Tab justifyContent={'flexStart'}>General</Tab>
              <Tab justifyContent={'flexStart'}>Content</Tab>
              <Tab justifyContent={'flexStart'}>Seo</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Grid
                  templateColumns={{ base: '1fr' }}
                  w='100%'
                  maxWidth='1000px'
                  gap='25px'
                >
                  <Stack spacing='20px' w='100%'>
                    <Stack spacing='20px' w='100%'>
                      <FormSection title={`${data?.[FETCH]?.title}`}>
                        <FormSectionInner>
                          <Field
                            component='text'
                            name='title'
                            placeholder='Title'
                            rules={{ required: true }}
                          />
                          <Field
                            component='text'
                            name='slug'
                            placeholder='Slug'
                            rules={{ required: true }}
                          />
                        </FormSectionInner>
                      </FormSection>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid
                  templateColumns={{ base: '1fr' }}
                  w='100%'
                  maxWidth='1000px'
                  gap='25px'
                >
                  <Stack spacing='20px' w='100%'>
                    <Stack spacing='20px' w='100%'>
                      <FormSection>
                        <FormSectionInner title='Hero'>
                          <Hero />
                        </FormSectionInner>
                        <FormSectionInner title='CTA'>
                          <CTA />
                        </FormSectionInner>
                      </FormSection>
                    </Stack>
                  </Stack>
                </Grid>
              </TabPanel>
              <TabPanel>
                <Grid
                  templateColumns={{ base: '1fr' }}
                  w='100%'
                  maxWidth='1000px'
                  gap='25px'
                >
                  <Stack spacing='20px' w='100%'>
                    <Stack spacing='20px' w='100%'>
                      <FormSection title={`${data?.[FETCH]?.title}`}>
                        <FormSectionInner>
                          <Field
                            component='page'
                            name='content'
                            placeholder='Content'
                          />
                        </FormSectionInner>
                      </FormSection>
                    </Stack>
                  </Stack>
                </Grid>
              </TabPanel>
              <TabPanel>
                <Flex mb='20px'>
                  <FormSection title='Seo'>
                    <FormSectionInner columns={1}>
                      <Field
                        component='text'
                        name='seo.title'
                        placeholder='SEO Title'
                      />
                      <Field
                        component='text'
                        name='seo.description'
                        placeholder='SEO Description'
                      />
                    </FormSectionInner>
                  </FormSection>
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>

        <Grid
          templateColumns={{ base: '1fr' }}
          w='100%'
          maxWidth='1000px'
          gap='25px'
        ></Grid>
      </EditView>
    </Meta>
  );
};

const CTA = () => {
  return (
    <Field component='switch' name='cta.show' label='Show call To action' />
  );
};
const Hero = () => {
  const hero = useWatch({ name: 'hero' });

  return (
    <>
      <FormSectionInner>
        <Field component='switch' name='hero.small' label='Small Banner?' />
        <Field component='text' name='hero.preview' placeholder='Pre-header' />
        <Field component='text' name='hero.title' placeholder='Title' />
        <Field component='text' name='hero.subtitle' placeholder='Sub Title' />
      </FormSectionInner>
      {!hero?.small && (
        <FormSectionInner columns={2}>
          <Field
            component='text'
            name='hero.link_one_text'
            placeholder='Link one text'
          />
          <Field
            component='text'
            name='hero.link_one_url'
            placeholder='Link one url'
          />
          <Field
            component='text'
            name='hero.link_two_text'
            placeholder='Link two text'
          />
          <Field
            component='text'
            name='hero.link_two_url'
            placeholder='Link two url'
          />
        </FormSectionInner>
      )}
      {!hero?.small && (
        <FormSectionInner columns={2}>
          <Field
            component='file'
            name='hero.video_thumbnail'
            placeholder='Video Preview Image'
          />
          <Field component='text' name='hero.video' placeholder='Video' />
        </FormSectionInner>
      )}
    </>
  );
};
export default Index;
