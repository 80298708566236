const Checkbox = {
    parts: ['icon', 'label', 'control'],
    defaultProps: { size: 'lg' },
    baseStyle: {
        control: {
            rounded: '5px',
        },
        label: {
            fontSize: '11px',
        },
    },
    sizes: {
        lg: {
            control: { w: 5, h: 5, borderColor: 'blackAlpha.500' },
            label: { fontSize: 'md' },
            icon: { fontSize: '0.625rem' },
        },
    },
    variants: {},
};

export default Checkbox;
