/* global localStorage, */
import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Flex, IconButton } from '@chakra-ui/react';
import PopConfirm from '../components/PopConfirm';
import { Video } from 'cloudinary-react';
import { useRecoilState } from 'recoil';
import { FiTrash } from 'react-icons/fi';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { mediaAtom as _mediaAtom } from '../state/media';
import { CloudinaryUploadContext } from '../providers/CloudinaryProvider';

const MediaLibraryComponent = ({
    onChange,
    name,
    modalButton,
    max_files,
    multiple,
    showOptions,
    isRepeater,
    value,
    max,
    ...rest
}) => {
    const { show } = useContext(CloudinaryUploadContext);

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [mediaAtom, setMediaAtom] = useRecoilState(_mediaAtom);

    const resetAtom = () => {
        const atom = [...mediaAtom];
        const index = atom.findIndex((e) => e.name === name);
        atom[index] = { name: name, files: [] };
        setMediaAtom(atom);
    };

    useEffect(() => {
        const active = localStorage.getItem('activeMediaField');
        const field = mediaAtom.find((f) => f.name === name);

        if (name === active && field && field.files.length !== 0) {
            setSelectedFiles([...selectedFiles, ...field.files]);
            onChange([...selectedFiles, ...field.files]);
            resetAtom();
        }
    }, [mediaAtom]);

    useEffect(() => {
        if (value) {
            setSelectedFiles(value);
        }
    }, [value]);

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            selectedFiles,
            result.source.index,
            result.destination.index
        );

        setSelectedFiles(items);
        onChange(items);
    };

    const removeImage = (index) => {
        const _uploaded = [...selectedFiles];
        _uploaded.splice(index, 1);
        setSelectedFiles(_uploaded);
        onChange(_uploaded);
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    return (
        <React.Fragment>
            <Flex>
                <Button
                    size='sm'
                    variant='outline'
                    colorScheme='secondary'
                    onClick={() => show(name)}
                >
                    Select video
                </Button>
            </Flex>

            <Box w='100%'>
                {selectedFiles && selectedFiles.length !== 0 && (
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId='droppable'>
                            {(provided, snapshot) => (
                                <Box
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    w='100%'
                                    mt='10px'
                                    mb='10px'
                                >
                                    {selectedFiles &&
                                        selectedFiles.length !== 0 &&
                                        selectedFiles.map((item, index) => (
                                            <Flex
                                                key={`video-${index}`}
                                                align='center'
                                                justify='flex-start'
                                                {...getItemStyle()}
                                            >
                                                <Video
                                                    publicId={item?.public_id}
                                                    cloudName={
                                                        import.meta.env
                                                            .VITE_CLOUDINARY_CLOUDNAME
                                                    }
                                                    controls
                                                    width='450px'
                                                />

                                                <Flex
                                                    as='span'
                                                    ml='auto'
                                                    align='center'
                                                    pl='20px'
                                                >
                                                    <PopConfirm
                                                        onConfirm={() =>
                                                            removeImage(index)
                                                        }
                                                        title='Are you sure you want to remove this video?'
                                                    >
                                                        <IconButton
                                                            variant='link'
                                                            ml='5px'
                                                            icon={<FiTrash />}
                                                        />
                                                    </PopConfirm>
                                                </Flex>
                                            </Flex>
                                        ))}
                                    {provided.placeholder}
                                </Box>
                            )}
                        </Droppable>
                    </DragDropContext>
                )}
            </Box>
        </React.Fragment>
    );
};

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    mb: '5px',
    rounded: 'lg',
    boxShadow: 'sm',
    border: '1px',
    borderColor: isDragging ? 'primary' : 'global.borderColour',
    bg: isDragging ? 'global.elementBgAlt' : 'global.elementBg',
    pr: '10px',
    ...draggableStyle,
});

export default MediaLibraryComponent;
