import React from 'react';

import { createContext } from 'react';
import links from '../links';

export const NucleusContext = createContext();

const XProvider = (props) => {
    const { routes, config, currentUserQuery, errors, children } = props;

    return (
        <NucleusContext.Provider
            value={{ routes, config, links, currentUserQuery, errors }}
        >
            {children}
        </NucleusContext.Provider>
    );
};

export default XProvider;
