import React from 'react';
import List from './List';
import Entry from './';

const modelName = 'Administrators';
const modelNameSingular = 'Administrator';

export const setup = {
  title: modelName,
  singular: modelNameSingular,
  model: modelName.replace(/\s+/g, '-').toLowerCase(),
  canDelete: false,
  canAddNew: false,
  canSearch: false,
  canFilter: true,
  canSelect: false,
  accessor: '_id',
};

const routes = [
  {
    path: `/${setup.model}`,
    element: React.createElement(List, { title: setup.modelName }),
    index: true,
  },
  {
    path: `/${setup.model}/:type`,
    element: React.createElement(Entry, {
      title: `${setup.singular}`,
    }),
  },
  {
    path: `/${setup.model}/:type/:id`,
    element: React.createElement(Entry, {
      title: `${setup.singular}`,
    }),
  },
];

export default routes;
