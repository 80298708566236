export default function processVariables (args, str) {
    if (!args.length) return str
    //console.log({ args })
    str += `( `
    for (let index = 0; index < args.length; index++) {
        const arg = args[index]
        str += `$${arg.name}:` 
        let arg_type = `${(arg.type.name) ? arg.type.name : arg.type.ofType.name}`
        if (arg.type.kind === "NON_NULL") arg_type += `!`
        if (arg.type.kind === 'LIST') arg_type = `[${arg_type}]`
        str += `${arg_type} `
        switch (arg.type.kind) {
            case ('LIST'): 
                str = `${str}`
                break
            default: 
        }
        
        
    }
    str += `)`
    return str
}