import { gql, useQuery } from '@apollo/client';
import { Flex, Spinner } from '@chakra-ui/react';
import React, { useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { Login } from '../containers/Login';
import { useSchemaQuery } from '../react-apollo-introspection';
import { authAtom } from '../state/auth';

// const CHECK_AUTH = gql`
//   query CheckAuth {
//     currentAdminUser {
//       user {
//         token
//         isAdmin
//         name
//         email
//         permission_role {
//           permissions {
//             title
//             code
//           }
//         }
//       }
//       isAdmin
//     }
//   }
// `;

const AuthProvider = ({ children }) => {
  const [checkAuth, { data, loading, error }] = useSchemaQuery(
    'adminuser_Current'
  );
  const [auth, setAuth] = useRecoilState(authAtom);

  useEffect(() => {
    checkAuth();
  }, []);

  useEffect(() => {
    if (data && data.adminuser_Current) {
      setAuth(data.adminuser_Current);
    }
  }, [data, setAuth]);

  if (loading) {
    return (
      <Flex w='100vw' h='100vh' align='center' justify='center'>
        <Spinner />
      </Flex>
    );
  }
  if (!auth && error) {
    return <Login />;
  }

  return <div>{children}</div>;
};

export default AuthProvider;
