/* eslint-disable */
const links = [
  // {
  //     group: 'Clients',
  //     link: '/clients',
  //     items: [],
  //     permissions: [],
  // },
  // {
  //     group: 'Experts',
  //     link: '/experts',
  //     items: [],
  //     permissions: ['viewUsers'],
  // },

  {
    group: 'Athletes',
    link: '/athletes',
    items: [],
    permissions: ['All'],
  },
  {
    group: 'Checklist',
    link: '/checklist',
    items: [],
    permissions: ['All'],
  },
  {
    group: 'Pages',
    link: '/pages',
    items: [],
    permissions: ['view-business'],
  },
  {
    group: 'Training Videos',
    link: '/training-videos',
    items: [],
    permissions: ['view-business'],
  },
  {
    group: 'Video Groups',
    link: '/videos-groups',
    items: [],
    permissions: ['view-business'],
  },
  {
    group: 'Settings',
    items: [
      {
        title: 'Admin Users',
        href: '/administrators',
      },
    ],
    permissions: ['Root'],
  },
];

export default links;
